export default {
  title: {
    show: false,
    text: 'World Population'
  },
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0,
    appendToBody: true,
    axisPointer: {
      type: 'shadow',
      shadowStyle: {
        color: 'rgba(0, 0, 0, 0.05)',
        width: '1'
      }
    }
  },
  legend: {
    top: '5%'
  },
  grid: {
    left: '0%',
    right: '10%',
    bottom: '10%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'value',
      boundaryGap: [0, 0.1],
      show: true,
      axisTick: {
        show: false
      },
      splitLine: {
        show: true
      },
      axisLabel: {
        show: true
      },
      splitNumber: 2
    },
    {
      type: 'value',
      boundaryGap: [0, 0.01],
      show: false,
      axisTick: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      }
    }
  ],
  yAxis: {
    type: 'category',
    data: ['Brazil', 'Indonesia', 'USA', 'India', 'China', 'World'],
    axisTick: {
      show: false
    },
    splitLine: {
      show: false
    },

    axisLabel: {
      show: true,
      fontSize: 10
    },
    nameTextStyle: {
      fontWeight: 30
    }
  },
  series: [
    {
      name: '',
      type: 'bar',
      xAxisIndex: 0,
      data: [18203, 23489, 29034, 104970, 131744, 630230],
      itemStyle: {
        color: '#3B78FF',
        borderRadius: 10
      },
      barWidth: '30%'
    },
    {
      name: '',
      type: 'bar',
      xAxisIndex: 1,
      data: [19325, 23438, 31000, 121594, 134141, 681807],
      itemStyle: {
        color: '#3DE4A9',
        borderRadius: 10
      },
      barWidth: '30%'
    }
  ]
}
